import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.svg";
import Toolstack from "./Toolstack";
import Experience from "../Experience";
import Contact from "./Contact";
import { Bounce } from "react-awesome-reveal";
import { styles } from "../styles";

function About() {
  return (
    <Container>
      <Experience />
      <Container className="mt-20">
        <h1 className="project-heading">
          Professional <strong className="yellow">Skillset </strong>
        </h1>
        <div className="mt-10">
        <Techstack />
        </div>
      
      </Container>

      <h1 className="project-heading">
        Tools <strong className="yellow">I use</strong>
      </h1>

      <Toolstack />
    </Container>
  );
}

export default About;
