import video from '../../Assets/mernbot.mov'
import close from "../../Assets/close.png"
import { useEffect } from 'react';
export default function ModalContent({ onClose }) {

    useEffect(()=>{
        let ele = document.getElementsByClassName('App')[0] 
        ele.style.opacity="0.1"
        return ()=>ele.style.opacity="1"
    }, [])
  
    return (
    <div className="video-modal">
      <video width="100%" height="100%" controls>
        <source src={video} type="video/mp4" />
      </video>
      <button className='modal__close' onClick={onClose}><span><img style={{width: '30px', cursor: 'pointer'}} src={close} /></span></button>
    </div>
  );
}
