import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";

import Button from "react-bootstrap/Button";
import { Link } from "react-scroll";
import logo from "../Assets/logo.png";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Link to="homeSection">
        <img src={logo} className="main-logo cursor-pointer" />
      </Link>

      {/* <Link to="homeSection" smooth={true} duration={200}>
              <span onClick={() => updateExpanded(false)} className="nav-link">
                Home
              </span>
            </Link> */}

      <Container>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Link to="homeSection" smooth={true} duration={200}>
              <span onClick={() => updateExpanded(false)} className="nav-link cursor-pointer">
                Home
              </span>
            </Link>

            <Link to="aboutSection" smooth={true} duration={200}>
              <span onClick={() => updateExpanded(false)} className="nav-link cursor-pointer">
                About
              </span>
            </Link>

            <Link to="experienceSection" smooth={true} duration={200}>
              <span onClick={() => updateExpanded(false)} className="nav-link cursor-pointer">
                Experience
              </span>
            </Link>

            <Link to="projectSection" smooth={true} duration={200}>
              <span onClick={() => updateExpanded(false)} className="nav-link cursor-pointer">
                Projects
              </span>
            </Link>

            <Nav.Item className="fork-btn">
              <Button
                href="https://github.com/reachanandhere"
                target="_blank"
                className="fork-btn-inner yellow"
              >
                Git
              </Button>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
