import React from "react";

import ContactDetails from "./ContactDetails";
import ContactForm from "./ContactForm";
import { styles } from "../styles";
import Map from "./Map";
const Contact = () => {
  return (
    <div className="mt-16">
      
      <h1 className={`${styles.sectionHeadText} project-heading`} style={{marginBottom:'4rem'}}>
        Contact <span className="yellow">Me</span>
      </h1>
      <div className="flex justify-center">
        <ContactDetails />
        <div className="h-auto w-1 bg-white sm:hidden"></div>
        <ContactForm />
      </div>
      <Map />
    </div>
  );
};

export default Contact;
