import {
  FirebaseLine,
  FirebaseOriginal,
  GitOriginal,
  NpmOriginalWordmark,
  PostmanOriginal,
  VscodeOriginal,
} from "devicons-react";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";


function Toolstack() {
  return (
    <Container className="mt-10">
      <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        <Col xs={4} md={2} className="tech-icons">
          <VscodeOriginal size={64} />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <GitOriginal size={64} />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <FirebaseOriginal size={64} />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <PostmanOriginal size={64} />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <NpmOriginalWordmark size={64} />
        </Col>
      </Row>
    </Container>
  );
}

export default Toolstack;
