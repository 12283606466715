import React, { useState } from "react";
import { Container, Row, Col, Fade } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import netflix from "../../Assets/Projects/netflix.jpeg";
import youtube from "../../Assets/Projects/youtube.jpeg";
import mernbot from "../../Assets/Projects/mernbot.JPG";
import giphy from "../../Assets/Projects/Giphy.png";
import trimmy from "../../Assets/Projects/Trimmy.png";
import hirrd from "../../Assets/Projects/hirrd.png";
import ExpenseTracker from "../../Assets/Projects/ExpenseTracker.png";
import RecommendationCards from "../Recommendation/RecommendationCards";
import { recommendations } from "../constants";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="yellow">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={hirrd}
              isBlog={false}
              title="Hirrd"
              description="HIRRD is Full Stack Web Applcation Job Portal. It can be used for Recruiter and job seekers to post a job and search for open jobs respectively. It is built using React JS, ShadCN and Subabase database. For seemless authentication, Clerk is used. It also has a feature to upload and download the resume."
              ghLink="https://github.com/reachanandhere/Hirrd"
              demoLink="https://hirred.netlify.app/"
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={trimmy}
              isBlog={false}
              title="Trimmy"
              description="Trimmy is a one stop solution for URL Shortening. It is built using React, ShadCN and Supabase. It also allows users to track analytics of the shortened URL. It also has a feature to create custom URL. It is provides a QR code for the shortened URL with a feature to download the QR code."
              ghLink="https://github.com/reachanandhere/Trimmy"
              demoLink="https://trimmy.co/"
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={ExpenseTracker}
              isBlog={false}
              title="Expense Tracker"
              description="Expense Tracker is an interactive web application to track your expenses. It is built using React, and Node and it utilizes the power of GraphQL with MongoDB to prevent overfetching of data. It also uses Apollo Client for state management and Passport for authentication."
              ghLink="https://github.com/reachanandhere/Expense-Tracker"
              demoLink="https://expense-tracker-cl3k.onrender.com/"
            />
          </Col>
          <Col md={6} lg={4}  className="project-card">
            <ProjectCard
              imgPath={mernbot}
              isBlog={false}
              title="MernBOT"
              description="MernBOT is a personalized ChatBOT built using MERN Stack and OpenAI. It used GPT3.5 API and is primarily used for generating technical coding solutions. With MongoDB integration, it also keeps a track of previously asked questions. It is in Beta version right now and V2 model is coming."
              ghLink="https://github.com/reachanandhere/mernbot"
              demoLink=""
            />
          </Col>

          <Col md={6} lg={4}  className="project-card">
            <ProjectCard
              imgPath={giphy}
              isBlog={false}
              title="Your Giphy"
              description="Your Giphy is a web app built in React using Tailwind. It uses Giphy API to show the top 50 gifs. It also has a search feature to search for gifs. It also has a feature to download the gif. It is a simple web app to show the power of React and Tailwind. It also allows users to share the gif on social media."
              ghLink="https://github.com/reachanandhere/Giphy"
              demoLink="https://yourgiphy.netlify.app/"
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={netflix}
              isBlog={false}
              title="Netflix Clone"
              description="Netflix Clone is a web app built in React using Tailwind. It uses APIs from TMDB - The Movie Database. It contains features like Trailer playback, home feed, movies coming soon, search/View for movie, tv shows. It also uses GPT 3.5 Turbo API for movie suggestions and Google Firebase for authentication."
              ghLink="https://github.com/reachanandhere/Netflix"
              demoLink="https://nxclone.netlify.app/"
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={youtube}
              isBlog={false}
              title="Youtube Clone"
              description="Youtube Clone uses the youtube API to show 50 top video specific to a region. It also implements the Demo Live Chat features and multiple nested commenting. It also uses Redux for managing state across the application. This is Beta mode and more updates are coming."
              ghLink="https://github.com/reachanandhere/YoutubeClone"
              demoLink="https://ytec.netlify.app/"
            />
          </Col>
        </Row>
      </Container>

      <Container className="mt-20">
        <h1 className="project-heading">
          Received <strong className="yellow"> Recommendations</strong>
        </h1>
        <div className="mt-10">
          <Row
            style={{
              justifyContent: "center",
              paddingBottom: "10px",
              marginTop: "10px",
            }}
          >
            {recommendations.map((recommendation, index) => {
              return (
                <Col md={10} className="recommendation-card mt-8">
                  <RecommendationCards
                    image={recommendation.image}
                    detail={recommendation.detail}
                    name={recommendation.name}
                    content={recommendation.content}
                    position={recommendation.position}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </Container>
  );
}

export default Projects;
