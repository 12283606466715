import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import Card from "react-bootstrap/Card";

function RecommendationCards(props) {
  return (
    <Fade>
      <Card className="recommendation-card-view p-4">
        <a href="" className="no-underline text-white cursor-pointer">
          <div className="flex">
            <img
              src={props.image}
              alt="card-img"
              className="h-32 w-32 rounded-full"
            />
            <div className="ml-8 content-center text-left">
              <h3>
                {props.name}
                <span className="text-gray-400 font-mono text-xl ml-2">
                  .1st
                </span>
              </h3>
              <h6>{props.detail}</h6>
              <h6 className="font-sans text-gray-400">{props.position}</h6>
            </div>
          </div>
        </a>

        {/* <Card.Img className="h-32 w-32 rounded-full"  src={props.image} alt="card-img" /> */}

        <div
          className="text-left mt-4"
          dangerouslySetInnerHTML={{ __html: props.content }}
        ></div>

        {"\n"}
        {"\n"}

        {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}
      </Card>
    </Fade>
  );
}
export default RecommendationCards;
