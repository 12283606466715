import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
const ContactDetails = () => {
  return (
    <div className="mx-20 mt-32 sm:hidden text-white">
      <div className="w-80 h-10 flex border-black p-4 content-center my-8">
        <div className="h-10 w-10 rounded-full transition ease-in  bg-yellow-500 hover:scale-125 duration-300 cursor-pointer">
          <FaPhoneAlt size={20} style={{ margin: "10px" }} />
        </div>{" "}
        <span className="font-sans ml-4 text-lg">+1-(240)-821-2620</span>
      </div>

      <div className="w-80 h-10 flex border-black p-4 content-center my-8">
        <div className="h-10 w-10  rounded-full transition ease-in  bg-yellow-500  hover:scale-125 duration-300">
          <MdEmail size={20} style={{ margin: "10px" }} />
        </div>{" "}
        <span className="font-sans ml-4 text-lg">anandverma552@gmail.com</span>
      </div>

      <div className="w-80 h-10 flex border-black p-4 content-center">
        <div className="h-10 w-10 rounded-full hover:scale-125 bg-yellow-500 transition-all ease-in delay-150">
          <IoLocationSharp size={20} style={{ margin: "10px" }} />
        </div>{" "}
        <span className="font-sans ml-4 text-lg">Washington, DC</span>
      </div>
    </div>
  );
};

export default ContactDetails;
