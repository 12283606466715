import React from "react";
import GitHubCalendar from "react-github-calendar";
import { Row } from "react-bootstrap";

function Github() {
  return (
    <Row style={{ justifyContent: "center" }}>
      <h1 className="project-heading" style={{ marginBottom: "4rem" }}>
        Days <strong className="yellow"> I Code</strong>
      </h1>

      <GitHubCalendar
        username="reachanandhere"
        blockSize={15}
        blockMargin={5}
        color="#5BFF33"
        fontSize={16}
      />
    </Row>
  );
}

export default Github;
