import {
  web,
  javascript,
  typescript,
  python,
  globant,
  html,
  css,
  reactjs,
  nodejs,
  mongodb,
  git,
  docker,
  carrent,
  jobit,
  tripguide,
  tcs,
  verificient,
  angular,
  sql,
  agile,
  firebase,
} from "../../projectAssets";

export const navLinks = [
  {
    id: "about",
    title: "About",
  },
  {
    id: "work",
    title: "Work",
  },
  {
    id: "contact",
    title: "Contact",
  },
];

export const creds = {
  service_id: "service_vsbfv8r",
  template_id: "template_gibhoim",
  public_key: "ZLwroG6dhUs0wh7Ao",
};

const services = [
  {
    title: "Web Development",
    icon: web,
  },
  {
    title: "JavaScript",
    icon: javascript,
  },
  {
    title: "TypeScript",
    icon: typescript,
  },
  {
    title: "Python",
    icon: python,
  },
];

const technologies = [
  {
    name: "HTML 5",
    icon: html,
  },
  {
    name: "CSS 3",
    icon: css,
  },
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "TypeScript",
    icon: typescript,
  },
  {
    name: "Angular",
    icon: angular,
  },
  {
    name: "React JS",
    icon: reactjs,
  },
  {
    name: "Node JS",
    icon: nodejs,
  },

  {
    name: "SQL",
    icon: sql,
  },
  {
    name: "MongoDB",
    icon: mongodb,
  },

  {
    name: "Git",
    icon: git,
  },
  {
    name: "Firebase",
    icon: firebase,
  },
  {
    name: "Docker",
    icon: docker,
  },
  {
    name: "Agile",
    icon: agile,
  },
];

const experiences = [
  {
    title: "Senior Software Engineer",
    company_name: "Globant - Jan'22-Dec'22",
    icon: globant,
    iconBg: "#383E56",
    date: "Jan 2022 - Dec 2022",
    points: [
      "Spearheaded a team of developers to automate the manual artifact generation process for Oracle DB users.",
      "Developed backend using Node JS and Python to exchange huge data chunks between web applications and SFTP Server.",
      "Delivered enhancements every quarter and earned clients’ confidence and appreciation.",
      "Performed SAST and DAST security scans for applications before every Production release.",
      "Optimized load time and performance of applications using Performance Optimization Techniques",
    ],
  },
  {
    title: "Software Development Engineer",
    company_name: "Verificient Technologies Inc. - Dec'20-Jan'22",
    icon: verificient,
    iconBg: "#E6DEDD",
    date: "Dec 2020 - Jan 2022",
    points: [
      "Developed end-to-end Proctoring solution for Universities based in US and Canada that has conducted more than 50000 proctoring sessions so far.",
      "Integrated Artificial Intelligence and Machine Learning in Proctortrack and Remotedesk.",
      "Integrated Computer Vision in Proctortrack and Remotedesk.",
      "Periodically collaborated with the Customer Support Management team to understand client’s requirements and implemented requested features in a quick time.",
      "Developed WCAG-compliant applications to ensure accessibility for all users.",
    ],
  },
  {
    title: "Systems Engineer",
    company_name: "Tata Consultancy Services - Sep'17-Dec20",
    icon: tcs,
    iconBg: "#383E56",
    date: "Sep 2017 - Dec 2020",
    points: [
      "Designed customer’s widely used fund comparison tool which generates real-time comparison of funds.",
      "Developed Financial Advisory management tool for Clients.",
      "Implemented a solution to help customers get 30% more web traffic on legacy monolith pages using DOM manipulation.",
      "Implemented Content Caching mechanism to ensure the smooth running of applications in Downtime.",
      "Implemented Next Gen Log on Layer to access all authorized pages in Financial Advisor Services Application.",
    ],
  },
];

const projects = [
  {
    name: "Car Rent",
    description:
      "Web-based platform that allows users to search, book, and manage car rentals from various providers, providing a convenient and efficient solution for transportation needs.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "mongodb",
        color: "green-text-gradient",
      },
      {
        name: "tailwind",
        color: "pink-text-gradient",
      },
    ],
    image: carrent,
    source_code_link: "https://github.com/",
  },
  {
    name: "Job IT",
    description:
      "Web application that enables users to search for job openings, view estimated salary ranges for positions, and locate available jobs based on their current location.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "restapi",
        color: "green-text-gradient",
      },
      {
        name: "scss",
        color: "pink-text-gradient",
      },
    ],
    image: jobit,
    source_code_link: "https://github.com/",
  },
  {
    name: "Trip Guide",
    description:
      "A comprehensive travel booking platform that allows users to book flights, hotels, and rental cars, and offers curated recommendations for popular destinations.",
    tags: [
      {
        name: "nextjs",
        color: "blue-text-gradient",
      },
      {
        name: "supabase",
        color: "green-text-gradient",
      },
      {
        name: "css",
        color: "pink-text-gradient",
      },
    ],
    image: tripguide,
    source_code_link: "https://github.com/",
  },
];

const recommendations = [
  {
    name: "Kapil Patil",
    detail: "Angular | React Js | NodeJs | Full Stack Developer | Frontend Developer | Typescript | JavaScript",
    position: "June 18, 2024, Kapil was Anand's mentor",
    profile: "https://www.linkedin.com/in/kapil-patil-0844715b/",
    image: "https://media.licdn.com/dms/image/v2/D4D03AQECyODUQnFNNg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1709827128887?e=1730332800&v=beta&t=tVHXsWRsEJkFv0JDj0m4Vsob2C0gjqz9hRwXuL8CD3E",
    content: "I had the pleasure of working closely with Anand at Verificient Technologies Inc., and I can confidently say that he is an exceptional Software Development Engineer. His technical expertise and efficient management of his workload really set him apart from others. Anand consistently delivered top-notch solutions, showing a deep understanding of software engineering principles and best practices in the industry. His knack for writing clean and efficient code is truly impressive. What also stands out about Anand is his teamwork and communication skills. He actively collaborated with colleagues, provided valuable insights, and explained complex concepts clearly. <br / > Dedicated, solution-oriented, and always eager to learn, Anand is a tremendous asset to any software development team. <br /> <br /> I highly recommend him for any engineering role, and I’m sure he’ll make significant contributions wherever he goes."
  },
  {
    name: "Rahul Patel",
    detail: "Faculty at Sunstone",
    position: "February 22, 2023, Rahul was Anand's professor",
    profile: "https://www.linkedin.com/in/rahul-patel-7087b29a/",
    image: "https://media.licdn.com/dms/image/v2/D4D03AQGBrGA51pQPPg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1687597819538?e=1730332800&v=beta&t=1eW6RbJJ5xf5ZIX9c5FyhFoc3FPRh0EAYba1quLxVTM",
    content: "I am pleased to write a recommendation for Anand, a former student of BE Computer Science. Anand has a strong technical background in languages such as JavaScript, Typescript, and Python. He is also skilled in web technologies and frameworks like Angular, React JS, Vue.JS, Node JS, Django, Express JS, Electron, HTML, CSS, Bootstrap, JSON, and AJAX. Additionally, he has experience using tools such as Docker, NPM, Angular-CLI, Webpack, JIRA, GIT, Bitbucket, Fiddler, Postman, and Bamboo. <br />  During his time as a Senior Software Engineer at Globant, Anand was responsible for developing products that added value to the company, adding features to existing applications, and improving user experience. He also has more than three years of experience working at TCS. <br /> Anand is not only technically competent but also has a creative side. He is a very good poet and has conducted various TechFest and Cultural Fest events. <br />Currently, Anand is pursuing his graduate studies at the University of Maryland. I am confident that his passion for technology and creativity will make him an asset to any team."
  },
  {
    name: "P Santosh Kumar",
    detail: "UI Architect | Senior Front End Engineer | Web Developer | JavaScript Enthusiast | Senior Angular Developer ",
    position: "August 18, 2020, Santosh was senior in Tata Consultancy Services",
    profile: "https://www.linkedin.com/in/ardent-p-santosh-kumar/",
    image: "https://media.licdn.com/dms/image/v2/C5103AQEUjnzCtgMQdg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1581240851778?e=1730332800&v=beta&t=U-X3YXCBq4npIBQuQdK5X-rIbW3fxYbGHJo7B5WvnHc",
    content: "Extremely talented and fun to work with. Dedicated and a determined software developer. One who is always on his toes to take on challenges and learn new skills."
   
     
   
  },
  {
    name: "Akshay Gupta",
    detail: "Angular | React Js | NodeJs | Full Stack Developer |Frontend Developer | Typescript | JavaScript",
    position: "October 1, 2018, Akshay worked with Anand on the same team",
    profile: "https://www.linkedin.com/in/akshayguptaujn/",
    image: "https://media.licdn.com/dms/image/v2/C4D03AQFMDPVBqhYZmg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1641308787905?e=1730332800&v=beta&t=wnCqu_1ko0YeThWp5snn8Ro73oIey-Mh2O_-15Nvpss",
    content: "Anand and I have worked on numerous project during our graduation. He always have been a great team-player and a programmer with innovative ideas. He was able to take us out of many loopholes that would have been a big toll on our projects. He has a passion of being excellent at what he does. Being well-versed with the domain technicality and fundamentals of programming, he can deliver solutions in whatever technology he's asked to work in. He will be a great bonus to any team he works.<br /> <br />I wish him all the best for all his future endeavors."
  },
];

export { services, technologies, experiences, projects, recommendations };
