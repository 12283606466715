import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import { Bounce } from "react-awesome-reveal";
import {
  AiFillGithub,
  AiFillInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import About from "../About/About";
import Projects from "../Projects/Projects";
import Contact from "../About/Contact";
import Github from "../About/Github";
import { Element } from "react-scroll";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />

        <Element name="homeSection">
          <Container className="home-content">
            <Row>
              <Col md={7} className="home-header ">
                <Bounce>
                  <div className="sm:p-4">
                    <div style={{ textAlign: "left" }}>
                      <Type />
                    </div>

                    <h1 className="heading-name sm:mt-20">
                      I'M
                      <strong className="main-name yellow"> ANAND VERMA</strong>
                    </h1>
                    <h3
                      style={{ paddingBottom: 15 }}
                      className="heading yellow"
                    >
                      A FULL STACK SOFTWARE ENGINEER.
                    </h3>

                    <p className="yellow">"I turn algorithms into poetry!" - Anand</p>

                    <Col md={12} className="home-about-social">
                      <ul className="home-about-social-links">
                        <li className="social-icons">
                          <a
                            href="https://github.com/reachanandhere"
                            target="_blank"
                            rel="noreferrer"
                            className="icon-colour  home-social-icons flex items-center justify-center"
                          >
                            <AiFillGithub />
                          </a>
                        </li>
                        <li className="social-icons">
                          <a
                            href="https://twitter.com/son_Of_Verma"
                            target="_blank"
                            rel="noreferrer"
                            className="icon-colour  home-social-icons"
                          >
                            <AiOutlineTwitter />
                          </a>
                        </li>
                        <li className="social-icons">
                          <a
                            href="https://www.linkedin.com/in/anandverma08"
                            target="_blank"
                            rel="noreferrer"
                            className="icon-colour  home-social-icons"
                          >
                            <FaLinkedinIn />
                          </a>
                        </li>
                        <li className="social-icons">
                          <a
                            href="https://www.instagram.com/reachanand_"
                            target="_blank"
                            rel="noreferrer"
                            className="icon-colour home-social-icons"
                          >
                            <AiFillInstagram />
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </div>
                </Bounce>
              </Col>

              <Col md={5} style={{ paddingBottom: 5 }}>
                <Bounce>
                  <img
                    src={homeLogo}
                    alt="home pic"
                    className="img-fluid sm:mt-8 md:mt-32 "
                    style={{ maxHeight: "450px" }}
                  />
                </Bounce>
              </Col>
            </Row>
          </Container>
        </Element>
      </Container>

      <Element name="aboutSection">
        <Home2 />
      </Element>

      <Element name="experienceSection">
        <About />
      </Element>

      <Element name="projectSection">
        <Projects />
      </Element>

      <div className="mt-20 sm:hidden">
        <Github />
      </div>

      <Contact />
    </section>
  );
}

export default Home;
