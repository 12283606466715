import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import profile from "../../Assets/image.jpg";

import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { Bounce, Slide } from "react-awesome-reveal";
import AboutCard from "../About/AboutCard";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <Bounce>
              <h1 style={{ fontSize: "2.6em" }} className="mt-8">
                FEW DETAILS <span className="yellow"> ABOUT </span> MYSELF
              </h1>

              <AboutCard />
            </Bounce>
          </Col>
          <Col md={4} className="myAvtar">
            <Bounce>
              <img
                src={profile}
                className=" mx-auto rounded-[50%] mt-48 "
                alt="avatar"
              />
            </Bounce>
          </Col>
        </Row>
        <Row></Row>
      </Container>
    </Container>
  );
}
export default Home2;
