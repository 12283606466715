import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hello, I am{" "}
            <span className="yellow">
              <b>
                <i>Anand Verma.</i>{" "}
              </b>
            </span>
            I am from{" "}
            <span className="yellow">
              {" "}
              <i>
                <b>India</b>
              </i>
            </span>{" "}
            and currently I am pursuing my masters from{" "}
            <span className="yellow">
              {" "}
              <i>
                <b> University of Maryland, College Park.</b>
              </i>
            </span>
            <br />I have a Professional work experience of 6 years as a software
            professional and have worked with service and product-centric
            organizations like{" "}
            <span className="yellow">
              <i>
                <b>
                  {" "}
                  Tata Consultancy Services, Vanguard, Deloitte and Verificient
                  Technologies and Globant
                </b>
              </i>
            </span>{" "}
            to design, develop, improve, and migrate legacy applications using
            modern technology stacks. <br></br>I have led teams on different
            fronts for technical, non-technical, and administrative tasks.
            Alongside technical skills, I actively collaborated with
            cross-functional business and sales teams to engage clients,
            comprehensively assess their requirements, and consistently
            delivered customer-centric solutions.
          </p>
          <p className="home-about-body">
            - I have been coding for a{" "}
            <span className="yellow">
              <i>
                <strong>decade now!</strong>{" "}
              </i>{" "}
            </span>
            <br /> - I love talking about
            <i>
              <b className="yellow">
                {" "}
                Web developement, System Design and Generative AI.{" "}
              </b>
            </i>
            <br /> - I belive I thrive in&nbsp;
            <i>
              <b className="yellow">fast-paced environments </b> and also{" "}
              <b className="yellow">
                am passionate about learning new trends in the market.
              </b>
            </i>
            <br />
            <br />
            Apart from coding, I love to
            <i>
              <b className="yellow"> workout, going to hike and camping.</b>
            </i>
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
