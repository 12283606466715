import React from "react";
import { Col, Row } from "react-bootstrap";

import {
  JavascriptOriginal,
  TypescriptOriginal,
  PythonOriginal,
  ReactOriginal,
  AngularOriginal,
  NodejsOriginal,
  NextjsOriginal,
  MongodbOriginal,
  MysqlOriginalWordmark,
  GraphqlPlain,
  PostgresqlOriginal,
  RedisOriginal,
  DockerOriginal,
  TensorflowLine,
  D3jsOriginal,
  TailwindcssOriginal,
  SassOriginal,
  ReduxOriginal,
  NgrxOriginal
  
} from "devicons-react";

function Techstack() {
  return (
    <Row  style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <JavascriptOriginal size={64} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <TypescriptOriginal size={64} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <PythonOriginal size={64} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <ReactOriginal size={64} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <AngularOriginal size={64} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <NodejsOriginal size={64} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <NextjsOriginal size={64} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <ReduxOriginal size={64} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <NgrxOriginal size={64} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <MysqlOriginalWordmark size={64} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <MongodbOriginal size={64} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <PostgresqlOriginal size={64} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <GraphqlPlain size={64} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <RedisOriginal size={64} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <DockerOriginal size={72} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
       <D3jsOriginal size={64} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
       <TensorflowLine size={64} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
       <TailwindcssOriginal size={64} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
       <SassOriginal  size={64} />
      </Col>
      
    </Row>
  );
}

export default Techstack;
