import React, { useState } from "react";
import emailjs from '@emailjs/browser';
import toast from "react-hot-toast";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const form = React.createRef();

  const sendEmail = (e) => {
    e.preventDefault();
    

      emailjs.send('service_vsbfv8r', 'template_2wz7ilf', {name, email, message}, {
        publicKey: 'ZLwroG6dhUs0wh7Ao',
      }).then(
        (response) => {
          toast.success("Email sent successfully");
          setName("");
          setEmail("");
          setMessage("");
          //console.log('SUCCESS!', response.status, response.text);
        },
        (error) => {
          //console.log('FAILED...', error);
        },
      );
  };

  return (
    <div className="mx-20 sm:mx-0 text-white">
      <form ref={form} className="text-left" onSubmit={sendEmail}>
        <div className="my-8  text-lg w-full">
          <label htmlFor="name" className="w-full">
            Your name
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="w-full h-10 border-black text-black p-4 content-center my-2 rounded-md"
            />
          </label>
        </div>

        <div className="my-8 text-lg">
          <label htmlFor="email" className="w-full">
            Your email
            <input
              type="email"
              id="email"
              name={email}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full h-10 border-black text-black p-4 content-center my-2 rounded-md"
            /> 
          </label>
        </div>
        <div className="my-8  text-lg">
          <label htmlFor="email">
            Your message
            <textarea
              type="text"
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              rows={5}
              className="w-full border-black text-black p-4  my-2 rounded-md max-h-36"
            />
          </label>
        </div>
        <button className="bg-yellow-500 p-2 w-full px-8 rounded-md font-bold text-xl" type="submit">Send</button>
      </form>
    </div>
  );
};

export default ContactForm;
