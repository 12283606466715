import React from "react";

const Map = () => {
  return (
    <div className="m-4">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d194446.2287175929!2d-77.2211572563453!3d38.860774184285795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7c6de5af6e45b%3A0xc2524522d4885d2a!2sWashington%2C%20DC!5e0!3m2!1sen!2sus!4v1719646895607!5m2!1sen!2sus"
        width="90%"
        height="450"
        style={{ border: 2, padding: 10, margin: "auto"}}
       
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Map;
