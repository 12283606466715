import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { createPortal } from "react-dom";
import ModalContent from "../Portal/ModalContent";

function ProjectCards(props) {
  const [showModal, setShowModal] = useState(false);
  const openDialog = (e)=>{
    setShowModal(true)
    e.preventDefault()
   
  }
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img"  className="h-[220px]"/>
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        <div className="flex justify-center">
          <a
            href={props.ghLink}
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <button
              className="flex"
              style={{
                backgroundColor: "#eab41e",
                color: "black",
                padding: 12,
                borderRadius: 8,
                margin: "0 10px",
              }}
              href={props.ghLink}
              target="_blank"
            >
              <strong>GitHub</strong>
            </button>
          </a>

          <a
            href={props.demoLink}
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            {props.title == "MernBOT" ? (
              <div>
                 <button
                  className="flex"
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    padding: 12,
                    borderRadius: 8,
                  }}
                  onClick={(e)=>openDialog(e)}
                >
                  <strong>App Demo</strong>
                </button>
              </div>
            ) : (
              <div>
                <button
                  className="flex"
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    padding: 12,
                    borderRadius: 8,
                  }}
                  target="_blank"
                >
                  <strong>Demo</strong>
                </button>
              </div>
            )}

            {showModal &&
              createPortal(
                <ModalContent onClose={() => setShowModal(false)} />,
                document.body
              )}
          </a>
        </div>

        {"\n"}
        {"\n"}

        {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
